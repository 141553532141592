@import "../../sassStyles/mixins";

.tabContainer {
  .tabHeader {
    @include flexContainer($gap: 0);
    position: relative;
    max-width: 1140px;
    margin: 0 auto;
    &:after {
      content: "";
      width: 100%;
      height: 4px;
      border-bottom: 2px solid var(--secondaryColor);
      position: absolute;
      bottom: 0;
      left: 0;
    }
    button {
      flex: 1;
      @include fontSize(
        $minSize: 1.6rem,
        $maxSize: 2.4rem,
        $preferredSize: 10vw
      );
    }
  }
}

.tabBox {
  padding: 8rem 0rem 0;
  -webkit-animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  .tabContentContainer {
    max-width: 1140px;
    margin: 0 auto;
  }
}

.tabHome {
  .tabImgContainer {
    @include flexContainer($gap: 70px);
    flex: 1;
    max-width: 1140px;
    margin: 0 auto;
    padding-inline: 2rem;
    img {
      cursor: pointer;
    }
  }
  .tabContentContainer {
    text-align: center;
    margin-block: 80px;
    padding-inline: 2rem;
    .tabContentTitle {
      margin-bottom: 20px;
      h1 {
        line-height: 29px;
        margin-bottom: 20px;
      }
      h4 {
        @include fontSize(
          $minSize: 2.2rem,
          $maxSize: 3.2rem,
          $preferredSize: 10vw,
          $color: var(--primaryColor)
        );
      }
    }
    p {
      @include fontSize(
        $minSize: 1.6rem,
        $maxSize: 2.4rem,
        $preferredSize: 10vw
      );
      line-height: 3.2rem;
    }
    .socialContainer {
      @include flexContainer($gap: 40px);
      margin-block-start: 50px;
      margin-block-end: 80px;
      a {
        img {
          max-width: 45px;
        }
      }
    }
  }
  .signupSection {
    text-align: center;
    margin-bottom: 15px;
    h2 {
      @include fontSize(
        $minSize: 1.8rem,
        $maxSize: 2.6rem,
        $preferredSize: 10vw,
        $color: var(--highlightColor)
      );
    }
    .signupInnerSection {
      @include backgroundImage($image: url("../../assets/blueStrip.png"));
      min-height: 190px;
      padding-block: 2rem;
      margin-top: 20px;
      > div {
        max-width: 1140px;
        position: relative;
        margin: 0 auto;
        h2 {
          color: var(--whiteColor);
        }
        p {
          @include fontSize(
            $minSize: 1.6rem,
            $maxSize: 1.8rem,
            $preferredSize: 10vw,
            $color: var(--whiteColor)
          );
          max-width: 60rem;
          margin: 10px auto 0;
        }
        button {
          @include fontSize(
            $minSize: 1.6rem,
            $maxSize: 2.2rem,
            $preferredSize: 10vw,
            $color: var(--whiteColor)
          );
          margin-top: 20px;
        }
        img {
          max-width: clamp(20rem, 25vw, 30rem);
          position: absolute;
          top: -44px;
          right: clamp(-7rem, 10vw, -60rem);
          filter: drop-shadow(4px 4px 6px rgb(0, 0, 0, 0.5));
        }
      }
    }
  }
}

.tabRules {
  max-width: 1140px;
  margin: 0 auto 5rem;
  .tabImgContainer {
    > div {
      position: relative;
      min-height: 485px;
      width: 100%;
      max-width: 90%;
      margin: 100px auto 0;
      img {
        position: absolute;
        max-width: clamp(20rem, 70vw, 57rem);
        filter: drop-shadow(4px 4px 6px rgb(0, 0, 0, 0.5));
        &:nth-of-type(1) {
          top: -99px;
          z-index: 2;
        }
        &:nth-of-type(2) {
          right: 0;
        }
      }
    }
  }
  .tabContentContainer {
    max-width: 100%;
    text-align: center;
    .tabContentTitle {
      margin-bottom: 20px;
    }
    p {
      @include fontSize(
        $minSize: 1.6rem,
        $maxSize: 2.1rem,
        $preferredSize: 10vw
      );
      line-height: 3.2rem;
      text-align: justify;
    }
    ul {
      list-style: none;
      margin: 50px 0 0;
      padding: 0;
      @include flexContainer;
      flex-direction: column;
      li {
        @include fontSize(
        $minSize: 1.6rem,
        $maxSize: 2.1rem,
        $preferredSize: 10vw
      );
      line-height: 3.2rem;
      text-align: justify;
      span {
        color: var(--primaryColor);
      }
      }
    }
  }
}

.tabAdventure {
  margin: 0 auto;
  .tabImgContainer {
    max-width: 1140px;
    margin: 0 auto;
    > div {
      img {
        max-width: clamp(50rem, 70vw, 80rem);
        display: block;
        margin: 0 auto;
      }
    }
  }
  .tabContentContainer {
    text-align: center;
    margin-block: 80px;
    padding-inline: 2rem;
    .tabContentTitle {
      margin-bottom: 20px;
      h1 {
        line-height: 29px;
        margin-bottom: 20px;
      }
      h4 {
        @include fontSize(
          $minSize: 2.2rem,
          $maxSize: 3.2rem,
          $preferredSize: 10vw,
          $color: var(--primaryColor)
        );
      }
    }
    p {
      @include fontSize(
        $minSize: 1.6rem,
        $maxSize: 2.4rem,
        $preferredSize: 10vw
      );
      line-height: 3.2rem;
    }
    .socialContainer {
      @include flexContainer($gap: 40px);
      margin-block-start: 50px;
      margin-block-end: 80px;
      a {
        img {
          max-width: 45px;
        }
      }
    }
  }
  .instaContainer {
    padding: 80px 100px;
    @include backgroundImage($image: url("../../assets/blueStrip.png"));
    .instaInnerBlock {
      @include flexContainer;
      flex-direction: column;
      border-radius: 35px;
      padding: 80px;
      background-color: var(--whiteColor);
    }
  }
  .hashTagContainer {
    background-color: var(--whiteColor);
    padding-block: 40px;
    h2 {
      @include fontSize(
        $minSize: 1.4rem,
        $maxSize: 4.2rem,
        $preferredSize: 10vw,
        $color: var(--primaryColor)
      );
      letter-spacing: 1rem;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 900px) {
  .tabContainer {
    .tabHeader {
      max-width: 90%;
    }
  }
  .tabBox,
  .tabAdventure {
    .tabContentContainer {
      max-width: 90%;
    }
  }
  .tabHome {
    .tabImgContainer {
      max-width: 90%;
    }
    .signupSection {
      .signupInnerSection {
        > div {
          max-width: 90%;
        }
        img {
          position: unset !important;
          margin: 25px auto 0;
          display: block;
        }
      }
    }
  }
  .tabRules {
    max-width: 90%;
    .tabImgContainer {
      > div {
        min-height: 250px;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .tabContainer {
    .tabHeader {
      max-width: 100%;
    }
  }
  .tabBox {
    .tabContentContainer {
      max-width: 100%;
    }
  }
  .tabHome {
    .tabImgContainer {
      max-width: 100%;
      gap: 10px;
    }
    .signupSection {
      .signupInnerSection {
        max-width: 100%;
        img {
          position: unset !important;
          margin-top: 25px;
        }
      }
    }
  }
  .tabAdventure {
    .tabImgContainer {
      max-width: 90%;
    }
    .tabContentTitle {
      h1 {
        line-height: 42px !important;
      }
    }
    .instaContainer {
      padding: 80px 20px;
      .instaInnerBlock {
        padding: 40px;
      }
    }
    .hashTagContainer {
      h2 {
        letter-spacing: 0.2rem;
      }
    }
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
