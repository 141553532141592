@import "./sassStyles/mixins";

@font-face {
  font-family: antonioBold;
  src: url("./assets/fonts/Antonio-Bold.ttf");
}

@font-face {
  font-family: blocklynGrunge;
  src: url("./assets/fonts/Blocklyn-Grunge.otf");
}

@font-face {
  font-family: blocklynGrungeItalic;
  src: url("./assets/fonts/Blocklyn-GrungeItalic.otf");
}

:root {
  /* Global Colors */
  --primaryColor: #ff4d00;
  --secondaryColor: #595a5c;
  --highlightColor: #167e89;
  --whiteColor: #fff;
  --customBlueColor: #174574;
  --customGreyColor: #bfbfbf;
  --customLightBlueColor: #93c4e5;
  --customYellowColor: #fabc17;

  /* Global Fonts */
  --primaryFont: antonioBold;
  --secondaryFont: blocklynGrunge;
  --secondaryItalicFont: blocklynGrungeItalic;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

:is(h1, h2, h3, h4, h5, h6, p) {
  margin: 0;
  padding: 0;
}

:is(h1) {
  font-family: var(--secondaryFont);
}

:is(h2, h3, h4, h5, h6, p, button, a, span, input, label, select, li) {
  font-family: var(--primaryFont);
}

img {
  width: 100%;
}

/* Global Font Sizes */

h1 {
  @include fontSize($minSize: 3.2rem, $maxSize: 5rem, $preferredSize: 10vw);
}

p {
  @include fontSize($minSize: 1.6rem, $maxSize: 2.2rem, $preferredSize: 10vw);
}

a {
  text-decoration: none;
}

/* Global Input Style */

.defaultInput, .defaultSelect {
  border: 2px solid var(--primaryColor);
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 1.8rem;
  outline: none !important;
  width: 100%;
  background: transparent;
}

input::placeholder {
  color: var(--secondaryColor) !important;
}

.uploadLogoContainer {
  .uploadContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-inline-start: 1.5rem;
    justify-content: space-between;
    width: 100%;
    border: 0.2rem solid var(--primaryColor);
    border-radius: 0.5rem;
    font-size: 1.8rem;
    color: var(--secondaryColor);
    label {
      background-color: var(--primaryColor);
      font-weight: 600;
      font-size: 1.6rem;
      letter-spacing: 0.1rem;
      color: var(--whiteColor);
      cursor: pointer;
      padding: 10px 10px;
    }

    #choosenFile {
      margin-left: 0.3rem;
    }
  }
}

.errorText {
  font-size: 1.2rem;
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: none;
}
