.feedContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    > div {
        width: 100%;
        flex: 1 1 22%;
        a {
            display: block;
        }
    }
    img, video {
        aspect-ratio: 1;
        object-fit: cover;
        width: 100%;
    }
}

@media only screen and (max-width: 550px) {
    .feedContainer {
        > div {
            flex: 1 1 38%;
        }
    }
}