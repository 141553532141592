@import "../sassStyles/mixins";

.logoContainer {
  text-align: center;
  img {
    max-width: clamp(50rem, 70vw, 90rem);
  }
  p {
    @include fontSize(
      $minSize: 1.4rem,
      $maxSize: 3.2rem,
      $preferredSize: 10vw,
      $color: var(--highlightColor)
    );
    letter-spacing: 0.8rem;
  }
}

@media only screen and (max-width: 550px) {
  .logoContainer {
    p {
      letter-spacing: 0.2rem;
    }
  }
}
