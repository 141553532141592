@import "./sassStyles/mixins";

.appContainer {
  @include flexContainer($justifyContent: flex-start);
  height: 100vh;
  flex-direction: column;
}

.pageContainer {
  @include backgroundImage($image: url("./assets/pageBg.jpg"));
  @include flexContainer;
  background-attachment: fixed;
  flex: 1 1 auto;
  width: 100%;
  flex-direction: column;
}

.pageInnerContainer {
  @include flexContainer($justifyContent: flex-start, $gap: 80px);
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  padding-block-start: 5rem;
  > div {
    width: 100%;
  }
}

// @media only screen and (max-width: 550px) {
//   .pageInnerContainer {
//     padding-inline: 2rem;
//   }
// }
