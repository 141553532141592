@mixin flexContainer($justifyContent: center, $alignItems: center, $gap: 20px) {
    display: flex;
    justify-content: $justifyContent;
    align-items: $alignItems;
    gap: $gap;
}

@mixin backgroundImage($image, $size: cover, $positon: center, $repeat: no-repeat) {
    background-image: $image;
    background-size: $size;
    background-position: $positon;
    background-repeat: $repeat;
}

@mixin fontSize($minSize, $maxSize, $preferredSize, $color: var(--secondaryColor)) {
    font-size: clamp($minSize, $preferredSize, $maxSize);
    color: $color;
}