@import "../../../sassStyles/mixins";

.popupContainer {
  @include flexContainer($gap: 0);
  flex-direction: column;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .popupInner {
    @include flexContainer($gap: 40px);
    @include backgroundImage($image: url("../../../assets/pageBg.jpg"));
    flex-direction: column;
    padding: 4rem;
    border-radius: 10px;
    width: 100%;
    max-width: 700px;
    box-shadow: 0 0 8px rgb(0, 0, 0, 0.3);
    position: relative;
    .crossContainer {
      position: absolute;
      top: 20px;
      right: 25px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .formSubmitText {
      text-align: center;
      h2 {
        font-size: 2.6rem;
        margin-bottom: 20px;
      }
      p {
        font-size: 1.8rem;
        color: var(--primaryColor);
        a {
          color: var(--highlightColor);
        }
      }
    }
    .formImgContainer {
      img {
        max-width: 20rem;
      }
    }
    form {
      @include flexContainer($gap: 30px);
      flex-direction: column;
      width: 100%;
      max-width: 80%;
      > div {
        @include flexContainer($gap: 10px);
        width: 100%;
        position: relative;
        > p {
          position: absolute;
          width: 100%;
          bottom: -18px;
          left: 0;
        }
        > div {
          width: 100%;
          position: relative;
          p {
            position: absolute;
            width: 100%;
            bottom: -18px;
            left: 0;
          }
        }
        &:last-of-type {
          flex-direction: row-reverse;
          justify-content: flex-end;
          input {
            max-width: 5%;
            min-height: 19px;
          }
          label {
            font-size: 1.6rem;
          }
        }
      }
      > div:nth-of-type(3),
      > div:nth-of-type(4) {
        flex-direction: column;
      }
    }
  }
}

.popupBg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 999;
  top: 0;
  left: 0;
}


@media only screen and (max-width: 550px) {
  .popupContainer {
    .popupInner {
      max-width: 100%;
      height: 100%;
      padding: 4rem 2rem;
      form {
        max-width: 100%;
      }
    }
  }
}